@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Light'), url('./GT-Walsheim-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Light-Oblique'), url('./GT-Walsheim-Light-Oblique.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Regular'), url('./GT-Walsheim-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Regular-Oblique'), url('./GT-Walsheim-Regular-Oblique.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Medium'), url('./GT-Walsheim-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Medium-Oblique'), url('./GT-Walsheim-Medium-Oblique.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Bold'), url('./GT-Walsheim-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Black'), url('./GT-Walsheim-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim-Black-Oblique'), url('./GT-Walsheim-Black-Oblique.otf');
  font-weight: 900;
  font-style: italic;
}
